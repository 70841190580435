"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearErrors = exports.hideError = exports.showError = void 0;
function showError(fieldName, msg) {
    var input = document.getElementById(fieldName);
    input.classList.add('form-loan__input--error');
    for (var i = 1; i < input.parentNode.children.length; i++) {
        input.parentNode.children[i].remove();
    }
    var span = document.createElement('span');
    span.classList.add('form-loan__span');
    span.classList.add('form-loan__error');
    span.innerText = msg;
    input.parentNode.insertBefore(span, input.nextSibling);
}
exports.showError = showError;
function hideError(fieldName) {
    var input = document.getElementById(fieldName);
    if (!input) {
        return;
    }
    input.classList.remove('form-loan__input--error');
    for (var i = 1; i < input.parentNode.children.length; i++) {
        input.parentNode.children[i].remove();
    }
}
exports.hideError = hideError;
function clearErrors() {
    var errorSpans = document.querySelectorAll('.form-loan__span.form-loan__error');
    var errorInputs = document.querySelectorAll('.form-loan__input--error');
    Array.from(errorSpans).forEach(function (span) {
        span.parentNode.removeChild(span);
    });
    errorInputs.forEach(function (input) {
        input.classList.remove('form-loan__input--error');
    });
}
exports.clearErrors = clearErrors;
