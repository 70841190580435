"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCookie = void 0;
function setCookie(key, value) {
    var expires = new Date();
    var domain = window.location.hostname;
    var cookieValue = encodeURIComponent(value);
    expires.setDate(expires.getDate() + 376);
    document.cookie = "".concat(key, "=").concat(value, ";path=/;domain=").concat(domain, ";expires=").concat(expires.toUTCString());
}
exports.setCookie = setCookie;
