"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    "firstname": "Имя",
    "lastname": "Фамилия",
    "surname": "Отчество",
    "birthday": "Дата рождения",
    "email": "Email",
    "mobile": "Мобильный телефон",
    "loan": {
        "usedCar": "Покупка Б/У авто",
        "technics": "Ремонт/покупка техники, мебели",
        "humanDeposit": "Деньги под залог авто",
        "cure": "На лечение",
        "business": "На бизнес",
        "otherCredit": "Погашение другого кредита",
        "untilSalary": "Деньги до зарплаты",
        "other": "другое"
    },
    "employmentItems": {
        "no": "Не трудоустроен",
        "official": "Устроен официально",
        "officialPrivate": "Официально в фирме через СПД/ФЛП",
        "private": "СПД",
        "unofficial": "Устроен не официально",
        "maternityLeave": "Декретный отпуск",
        "pensioner": "Пенсионер"
    },
    "employment": "Трудоустройство",
    "continue": "Продолжить",
    "passport": "Серия и номер паспорта",
    "goal": "Цель займа",
    "inn": "(ИНН) Индивидуальный налоговый номер",
    "agree": "Даю согласие на <a href=\"HREF\">обработку моих персональных данных</a> и на получение рекламных и других информационных сообщений",
    "validationErrorEmail": "Пожалуйста, введите ваш действующий email"
};
