"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    firstname: "First name",
    lastname: "Last name",
    birthday: "Birthday",
    email: "Email",
    mobile: "Mobile phone",
    continue: "Continue",
    agree: 'I agree <a href="HREF">to the processing of my personal data</a> and to receive advertising and other information messages',
    validationErrorEmail: "Please enter your valid email",
    validationErrorPhone: "Enter your valid phone number",
    waitPageText: "<p>The selection of offers for your profile is in progress. <br/> <br/>\n  5 seconds remaining.</p>"
};
