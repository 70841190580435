"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    "firstname": "නම",
    "lastname": "වාසගම",
    "birthday": "උපන්දිනය",
    "email": "විද්‍යුත් තැපෑල",
    "mobile": "ජංගම දූරකථන අංකය",
    "continue": "ඉදිරියට යන්න",
    "agree": "මම මගේ පුද්ගලික දත්ත ප්‍රචාරණ සහ අනෙකුත් තොරතුරු පණිවිඩ සැකසීමට එකඟ වෙමි",
    "validationErrorEmail": "කරුණාකර ඔබගේ වලංගු ඊමේල් ලිපිනය ඇතුලත් කරන්න",
    validationErrorPhone: "හොඳයිද ඔබගේ දුරකථන අංකයක් ඇතුලත් කරන්න",
    waitPageText: "<p>\u0D94\u0DB6\u0D9C\u0DDA \u0DC0\u0DD9\u0DB1\u0DC3\u0DC0\u0DD3\u0DB8\u0DCA \u0DB4\u0DCA\u200D\u0DBB\u0DC4\u0DCF\u0DC3\u0DBA\u0DA7 \u0DC3\u0DC4\u0DCF\u0DBA \u0DC0\u0DD2\u0DBA. <br/> <br/>\n    5 \u0DC4\u0DC0\u0DD4\u0DC6\u0DB1\u0DCA \u0DC0\u0DBD\u0DA7 \u0D89\u0DC0\u0DAD\u0DCA.</p>"
};
