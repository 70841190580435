"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    firstname: "Họ và tên",
    birthday: "Ngày sinh",
    email: "Email",
    mobile: "Số ĐTDĐ",
    continue: "Tiến hành",
    validationErrorEmail: "Vui lòng nhập email hợp lệ của bạn",
    validationErrorPhone: "Nhập số điện thoại hợp lệ của bạn.",
    waitPageText: "<p>Vi\u1EC7c l\u1EF1a ch\u1ECDn c\u00E1c \u01B0u \u0111\u00E3i cho h\u1ED3 s\u01A1 c\u1EE7a b\u1EA1n \u0111ang \u0111\u01B0\u1EE3c ti\u1EBFn h\u00E0nh.\n    <br/><br/> C\u00F2n 5 gi\u00E2y.</p>"
};
