"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    "firstname": "Imię",
    "lastname": "Nazwisko",
    "surname": "",
    "birthday": "Data urodzenia",
    "email": "E-mail",
    "mobile": "Numer telefonu",
    "loan": [
        "Codzienne potrzeby",
        "Spłata innego kredytu",
        "Zakup sprzętu AGD",
        "Leczenie",
        "Naprawa samochodu",
        "Naprawa mieszkania",
        "Urlop",
        "Inny powód"
    ],
    "continue": "Kontynuuj",
    "passport": "Seria i numer dowodu osobistego",
    "inn": "PESEL",
    "agree": "Wyrażam zgodę <a href=\"HREF\">na przetwarzanie moich danych osobowych</a> oraz otrzymywanie wiadomości reklamowych i innych informacji.",
    "validationErrorEmail": "Proszę podać swój prawidłowy adres e-mail.",
    "validationErrorPhone": "Wpisz swój prawidłowy numer telefonu.",
    "waitPageText": "<p>Trwa selekcja ofert dla Twojego profilu.<br/><br/>\n    Pozosta\u0142o 5 sekund.</p>"
};
