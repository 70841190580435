"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    "firstname": "Ім'я",
    "lastname": "Прізвище",
    "surname": "По батькові",
    "birthday": "Дата народження",
    "email": "Електронна пошта",
    "mobile": "Мобільний телефон",
    "loan": [
        "Щоденні потреби",
        "Оплатити інший кредит",
        "Придбання побутової техніки",
        "Лікування",
        "Ремонт автомобіля",
        "Ремонт квартири",
        "Відпустка",
        "Інша причини"
    ],
    "continue": "Продовжити",
    "passport": "Серія та номер паспорта",
    "inn": "Індивідуальний податковий номер (ІПН)",
    "agree": "Даю згоду <a href=\"HREF\">на обробку моїх персональних даних</a> та отримання рекламних та інших інформаційних повідомлень.",
    "validationErrorEmail": "Введіть свою дійсну електронну адресу",
    "validationErrorPhone": "Введіть свій дійсний номер телефону",
    "waitPageText": "<p>\u0412\u0438\u043A\u043E\u043D\u0443\u0454\u0442\u044C\u0441\u044F \u043F\u0456\u0434\u0431\u0456\u0440 \u043F\u0440\u043E\u043F\u043E\u0437\u0438\u0446\u0456\u0439 \u043F\u0456\u0434 \u0432\u0430\u0448 \u043F\u0440\u043E\u0444\u0456\u043B\u044C.<br/><br/>\n    \u0417\u0430\u043B\u0438\u0448\u0438\u043B\u043E\u0441\u044C 5 \u0441\u0435\u043A\u0443\u043D\u0434.</p>"
};
