"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    firstname: "Имя",
    lastname: "Фамилия",
    surname: "Отчество",
    birthday: "Дата рождения",
    email: "Email",
    mobile: "Мобильный телефон",
    loan: {
        usedCar: "Покупка Б/У авто",
        technics: "Ремонт/покупка техники, мебели",
        humanDeposit: "Деньги под залог авто",
        cure: "На лечение",
        business: "На бизнес",
        otherCredit: "Погашение другого кредита",
        untilSalary: "Деньги до зарплаты",
        other: "другое"
    },
    employmentItems: {
        no: "Не трудоустроен",
        official: "Устроен официально",
        officialPrivate: "Официально в фирме через СПД/ФЛП",
        private: "СПД",
        unofficial: "Устроен не официально",
        maternityLeave: "Декретный отпуск",
        pensioner: "Пенсионер"
    },
    employment: "Трудоустройство",
    continue: "Продолжить",
    passport: "Серия и номер паспорта",
    goal: "Цель займа",
    inn: "(ИНН) Индивидуальный налоговый номер",
    agree: "Даю согласие на <a href=\"HREF\">обработку моих персональных данных</a>  и на получение рекламных и других информационных сообщений",
    validationErrorEmail: "Пожалуйста, введите ваш действующий email",
    validationErrorPhone: "Пожалуйста, введите ваш действующий номер телефона",
    waitPageText: "<h1>\u041F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u043E\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u0435 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u043E \u043D\u0430 \u043F\u043E\u0447\u0442\u0443.</h1>\n<p><span>\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435</span> \u043F\u0430\u043F\u043A\u0443 <span>\"\u0421\u041F\u0410\u041C\"</span>, \u0435\u0441\u043B\u0438 \u043D\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u0438 \u0435\u0433\u043E.</p>\n<p>\u041F\u0435\u0440\u0435\u0445\u043E\u0434 \u043D\u0430 \u043E\u0441\u0442\u0430\u043B\u044C\u043D\u044B\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u0447\u0435\u0440\u0435\u0437 3 \u0441\u0435\u043A\u0443\u043D\u0434\u044B.</p>"
};
