"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    "firstname": "Nombre",
    "lastname": "Apellido paterno",
    "birthday": "Fecha de nacimiento",
    "email": "Email",
    "mobile": "Teléfono Móvil",
    "continue": "Continuar",
    "agree": "Acepto <a href=\"HREF\">el tratamiento de mis datos personales</a> y recibir publicidad y otros mensajes informativos",
    "validationErrorEmail": "Por favor, introduce tu correo electrónico válido",
    validationErrorPhone: "Ingresa tu número de teléfono válido",
    waitPageText: "<h3>Se envi\u00F3 una oferta personal por correo electr\u00F3nico.</h3>\n    <p><span>Revisa</span> tu carpeta de <span>SPAM</span> si no lo has recibido.</p>\n    <p>Ir a otras ofertas en 3 segundos</p>"
};
